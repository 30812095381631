import "fullcalendar/dist/fullcalendar.min.css";

import "@fortawesome/fontawesome-free/css/all.css";

import 'regenerator-runtime/runtime';
import "styles.less";
import "bootstrap/js/dropdown";
import "bootstrap/js/collapse";
import "bootstrap/js/modal";
import "bootstrap/js/tooltip";
import "bootstrap/js/popover";
import "bootstrap/js/alert";
import "tinymce";

import {AureliaConfiguration} from "aurelia-configuration";
import Backend from 'i18next-chained-backend';
import HttpApi from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import sprintf from "i18next-sprintf-postprocessor";
import LanguageDetector from "i18next-browser-languagedetector";
import {PLATFORM} from 'aurelia-pal';
import jss from "jss";
import preset from "jss-preset-default";

jss.setup(preset());

export function configure(aurelia) {
    aurelia.use
        .standardConfiguration()
        .plugin(PLATFORM.moduleName('aurelia-configuration'), config => {
            //Force no caching for config loading
            config.setConfig('config.json?time=' + Date.now())
        })
        .plugin(PLATFORM.moduleName('aurelia-dialog'), config => {
            config.useDefaults();
            config.settings.startingZIndex = 2000;
            config.settings.lock = false;
            config.settings.centerHorizontalOnly = true;
        })
        .plugin(PLATFORM.moduleName('aurelia-i18n'), instance => {

            let appConfig = aurelia.container.get(AureliaConfiguration);

            instance.i18next
                .use(Backend)
                .use(LanguageDetector)
                .use(sprintf);

            return instance.setup({
                backend: {
                    backends: [
                        LocalStorageBackend,  // primary
                        HttpApi               // fallback
                    ],
                    backendOptions: [
                        {
                            expirationTime: 20 * 1000,
                        }, {                                  // <-- configure backend settings
                            loadPath: appConfig.get('apiUrl') + 'translation/catalogue?_locale={{lng}}',
                            parse: function (data) {
                                return JSON.parse(data);
                            }
                        }]
                },
                postProcess: 'sprintf',
                whitelist: [
                    'de',
                    'de_AT',
                    'de_CH',
                    'en',
                    'nl',
                    'cs',
                    'pl'
                ],
                keySeparator: false,
                nsSeparator: false,
                lng: 'de',
                fallbackLng: 'de',
                debug: false //was causing too many consoles
            });
        })
        .plugin(PLATFORM.moduleName('aurelia-validation'))
        .plugin(PLATFORM.moduleName('aurelia-sortablejs'))
        .developmentLogging()

        // see http://aurelia.io/hub.html#/doc/article/aurelia/framework/latest/app-configuration-and-startup/6

        .globalResources(
            PLATFORM.moduleName('converters/date'),
            PLATFORM.moduleName('converters/filesize'),
            PLATFORM.moduleName('converters/integer'),
            PLATFORM.moduleName('converters/join'),
            PLATFORM.moduleName('converters/json'),
            PLATFORM.moduleName('converters/keys'),
            PLATFORM.moduleName('converters/number'),
            PLATFORM.moduleName('converters/percentage'),
            PLATFORM.moduleName('converters/field-color'),
            PLATFORM.moduleName('converters/time'),
            PLATFORM.moduleName('converters/truncate'),
            PLATFORM.moduleName('value-converter/clip-value-converter'),

            PLATFORM.moduleName('offer/error-display'),
            PLATFORM.moduleName('context-model-fields/context-model-fields'),
            PLATFORM.moduleName('accounting/reports/pal-statement'),
            PLATFORM.moduleName('accounting/reports/balance-list'),
            PLATFORM.moduleName('accounting/reports/balance-statement'),
            PLATFORM.moduleName('accounting/reports/bwa-statement'),
            PLATFORM.moduleName('accounting/reports/report-item'),
            PLATFORM.moduleName('accounting/reports/vat-statement'),
            PLATFORM.moduleName('accounting/ledger/entries'),
            PLATFORM.moduleName('annotation-viewer/annotation-viewer'),
            PLATFORM.moduleName('action/action'),
            PLATFORM.moduleName('action/action-display'),
            PLATFORM.moduleName('action/button-with-icon'),
            PLATFORM.moduleName('address/address-input'),
            PLATFORM.moduleName('address/address-selector'),
            PLATFORM.moduleName('banking-api/assign'),
            PLATFORM.moduleName('calendar/availability-calendar'),
            PLATFORM.moduleName('calendar/calendar'),
            PLATFORM.moduleName('calendar/timelog-calendar'),
            PLATFORM.moduleName('calendar/schedule-calendar/schedule-calendar'),
            PLATFORM.moduleName('cell/cell'),
            PLATFORM.moduleName('cell/type/address'),
            PLATFORM.moduleName('cell/type/age'),
            PLATFORM.moduleName('cell/type/bic'),
            PLATFORM.moduleName('cell/type/boolean'),
            PLATFORM.moduleName('cell/type/opening-times'),
            PLATFORM.moduleName('cell/type/choice'),
            PLATFORM.moduleName('cell/type/collection'),
            PLATFORM.moduleName('cell/type/color'),
            PLATFORM.moduleName('cell/type/codemirror'),
            PLATFORM.moduleName('cell/type/date'),
            PLATFORM.moduleName('cell/type/default'),
            PLATFORM.moduleName('cell/type/number'),
            PLATFORM.moduleName('cell/type/date-interval'),
            PLATFORM.moduleName('cell/type/due-date'),
            PLATFORM.moduleName('cell/type/email'),
            PLATFORM.moduleName('cell/type/email-with-content'),
            PLATFORM.moduleName('cell/type/url'),
            PLATFORM.moduleName('cell/type/file'),
            PLATFORM.moduleName('cell/type/file-path'),
            PLATFORM.moduleName('cell/type/military-time'),
            PLATFORM.moduleName('cell/type/money/money-display'),
            PLATFORM.moduleName('cell/type/money'),
            PLATFORM.moduleName('cell/type/multiline'),
            PLATFORM.moduleName('cell/type/percentage'),
            PLATFORM.moduleName('cell/type/phone'),
            PLATFORM.moduleName('cell/type/price-set'),
            PLATFORM.moduleName('cell/type/price-charge'),
            PLATFORM.moduleName('cell/type/reference-display'),
            PLATFORM.moduleName('cell/type/time'),
            PLATFORM.moduleName('cell/type/translatable'),
            PLATFORM.moduleName('cell/type/translated'),
            PLATFORM.moduleName('cell/type/date-range'),
            PLATFORM.moduleName('costs/cost-table'),
            PLATFORM.moduleName('choice/autofilling-choice-element'),
            PLATFORM.moduleName('choice/choice-element'),
            PLATFORM.moduleName('choice/choice-value-converter'),
            PLATFORM.moduleName('choice/empty-array-value-converter'),
            PLATFORM.moduleName('choice/subtype-airline/airline-choice-element'),
            PLATFORM.moduleName('choice/subtype-airport/airport-choice-element'),
            PLATFORM.moduleName('choice/subtype-checkboxes-table/checkboxes-table-choice-element'),
            PLATFORM.moduleName('choice/subtype-radio-table/radio-table-choice-element'),
            PLATFORM.moduleName('choice/subtype-checkboxes/checkboxes-choice-element'),
            PLATFORM.moduleName('choice/subtype-images/images-choice-element'),
            PLATFORM.moduleName('choice/subtype-radios/radios-choice-element'),
            PLATFORM.moduleName('choice/subtype-room-matrix/room-matrix-choice-element'),
            PLATFORM.moduleName('choice/subtype-seat-assignment/seat-assignment-choice-element'),
            PLATFORM.moduleName('choice/tag-element'),
            PLATFORM.moduleName('color-palette/color-palette'),
            PLATFORM.moduleName('activity/activity-viewer'),
            PLATFORM.moduleName('condition-builder/condition-builder'),
            PLATFORM.moduleName('costs/cost-assignment'),
            PLATFORM.moduleName('costs/cost-report-assignments'),
            PLATFORM.moduleName('costs/margin-report'),
            PLATFORM.moduleName('crm-qa-survey/customer-mood-converter'),
            PLATFORM.moduleName('crm-qa-survey/questions-editor'),
            PLATFORM.moduleName('crm-qa-survey/survey-average-rating-converter'),
            PLATFORM.moduleName('currency/currency-sum-value-converter'),
            PLATFORM.moduleName('currency/currency-value-converter'),
            PLATFORM.moduleName('date-input/age-value-converter'),
            PLATFORM.moduleName('date-input/current-date-value-converter'),
            PLATFORM.moduleName('date-input/date-input'),
            PLATFORM.moduleName('date-input/date-range-input'),
            PLATFORM.moduleName('date-input/due-display'),
            PLATFORM.moduleName('date-input/modify-date-value-converter'),
            PLATFORM.moduleName('editor/editor'),
            PLATFORM.moduleName('email-campaign/email-campaign-report'),
            PLATFORM.moduleName('email-message-body/email-message-body'),
            PLATFORM.moduleName('email-message-body/email-message-body-embedded'),
            PLATFORM.moduleName('text/input'),
            PLATFORM.moduleName('text/textarea'),
            PLATFORM.moduleName('text/htmlarea'),
            PLATFORM.moduleName('text/codemirror'),
            PLATFORM.moduleName('text/translatable'),
            PLATFORM.moduleName('todo/overdue-todo'),
            PLATFORM.moduleName('file/file-input'),
            PLATFORM.moduleName('file/thumbnail/thumbnail'),
            PLATFORM.moduleName('file/uploaded-file'),
            PLATFORM.moduleName('file/uploader'),
            PLATFORM.moduleName('file/translatable-image'),
            PLATFORM.moduleName('flight/flight-segment-display'),
            PLATFORM.moduleName('journey-flights/flights'),
            PLATFORM.moduleName('journey-order-notes/notes'),
            PLATFORM.moduleName('form/bulk-form'),
            PLATFORM.moduleName('form/converter/collection-to-text-converter'),
            PLATFORM.moduleName('form/default-form-layout.html'),
            PLATFORM.moduleName('form/form'),
            PLATFORM.moduleName('form/helper/form-error'),
            PLATFORM.moduleName('form/helper/form-label'),
            PLATFORM.moduleName('form/helper/form-row'),
            PLATFORM.moduleName('hauser/frontend-homepage-configuration/frontend-homepage-configuration'),
            PLATFORM.moduleName('hauser/frontend-menu-configuration/frontend-menu-configuration'),
            PLATFORM.moduleName('history/history-display'),
            PLATFORM.moduleName('integrity-chain/indicator'),
            PLATFORM.moduleName('loading-overlay/loading-overlay'),
            PLATFORM.moduleName('log/email-display'),
            PLATFORM.moduleName('log/print-display'),
            PLATFORM.moduleName('log/log'),
            PLATFORM.moduleName('log/log-footer'),
            PLATFORM.moduleName('log/log-automatically-stamped'),
            PLATFORM.moduleName('media-database/media-bundle/share-media-bundle-view'),
            PLATFORM.moduleName('menu/dropdown-menu'),
            PLATFORM.moduleName('menu/menu'),
            PLATFORM.moduleName('notification/notification'),
            PLATFORM.moduleName('merge/duplicates-display'),
            PLATFORM.moduleName('moebelnet/moebelnet-keyword-tool'),
            PLATFORM.moduleName('money/money-input'),
            PLATFORM.moduleName('filter-condition/filter-conditions-input'),
            PLATFORM.moduleName('filter-condition/filter-conditions-prediction'),
            PLATFORM.moduleName('filter-condition/filter-conditions-calculate-count'),
            PLATFORM.moduleName('object-conditions/object-conditions-input'),
            PLATFORM.moduleName('object-display/object-display'),
            PLATFORM.moduleName('object-display/property-display'),
            PLATFORM.moduleName('order/payment-matrix'),
            PLATFORM.moduleName('order/tourism/booking-state-display'),
            PLATFORM.moduleName('order/calculation/calculation-aurelia'),
            PLATFORM.moduleName('order/tourism/booking-state-list'),
            PLATFORM.moduleName('order/offer-configuration/offer-aurelia'),
            PLATFORM.moduleName('deutsche-bahn/result-table'),
            PLATFORM.moduleName('order/booking-assistant'),
            PLATFORM.moduleName('order/tourism/cart'),
            PLATFORM.moduleName('order/tourism/menu-toolbar'),
            PLATFORM.moduleName('order/tourism/cart-view/title-display/title-display'),
            PLATFORM.moduleName('order/tourism/fill-new-line-value-converter'),
            PLATFORM.moduleName('order/tourism/convert-provider-value-converter'),
            PLATFORM.moduleName('order/paticipants-list/sio-participants-list-view'),
            PLATFORM.moduleName('permission/permission-condition-viewer'),
            PLATFORM.moduleName('permission/permission-viewer'),
            PLATFORM.moduleName('phone/phone-display'),
            PLATFORM.moduleName('range-slider/range-slider-input'),
            PLATFORM.moduleName('phone/phone-input'),
            PLATFORM.moduleName('popover/popover'),
            PLATFORM.moduleName('popover/debug'),
            PLATFORM.moduleName('preview/preview'),
            PLATFORM.moduleName('price-set/contingent'),
            PLATFORM.moduleName('price-set/contingentDisplayAurelia'),
            PLATFORM.moduleName('price-set/price-set-input-new'),
            PLATFORM.moduleName('price-set/contingent-set'),
            PLATFORM.moduleName('price-set/stop-sale-set'),
            PLATFORM.moduleName('reference/reference-display'),
            PLATFORM.moduleName('reporting/hauser-agency-report'),
            PLATFORM.moduleName('settings/settings'),
            PLATFORM.moduleName('settings/settings-editor'),
            PLATFORM.moduleName('toma-mask/toma-mask'),
            PLATFORM.moduleName('statistics/statistics'),
            PLATFORM.moduleName('table/bulk-operations-display'),
            PLATFORM.moduleName('table/pagination/pagination'),
            PLATFORM.moduleName('table/sort/sort-indicator'),
            PLATFORM.moduleName('table/table'),
            PLATFORM.moduleName('template-variables-schema/template-variables-helper-panel'),
            PLATFORM.moduleName('template-variables-schema/template-variables-schema-field'),
            PLATFORM.moduleName('time-tracking/time-tracking'),

            PLATFORM.moduleName('calendar/schedule-calendar/availability-scheduler/calendar'),
            PLATFORM.moduleName('calendar/schedule-calendar/availability-scheduler/calendar-display-aurelia'),

            PLATFORM.moduleName('tourism/ship/vacancy'),
            PLATFORM.moduleName('tourism/ship/vacancy-display-aurelia'),
            PLATFORM.moduleName('tourism/pnr-search/pnr-search'),
            PLATFORM.moduleName('tourism/pnr-search/amadeus/amadeus-pnr-display'),
            PLATFORM.moduleName('tourism/pnr-search/amadeus/amadeus-search-results'),
            PLATFORM.moduleName('tourism/pnr-search/amadeus/amadeus-pnr-search-filters'),
            PLATFORM.moduleName('tourism/pnr-search/sabre/sabre-gmr-display'),
            PLATFORM.moduleName('tourism/pnr-search/sabre/sabre-gmr-search-results'),
            PLATFORM.moduleName('tourism/pnr-search/sabre/sabre-gmr-search-filters'),

            PLATFORM.moduleName('tourism/entry-and-visa'),
            PLATFORM.moduleName('tourism/holiday-check-rating/holiday-check'),
            PLATFORM.moduleName('tourism/itinerary/itinerary-display'),
            PLATFORM.moduleName('tourism/general/calculated-result'),
            PLATFORM.moduleName('tourism/general/season-value-converter'),
            PLATFORM.moduleName('tourism/hotel-calculation/calculation'),
            PLATFORM.moduleName('tourism/hotel-calculation/calculation-overview'),
            PLATFORM.moduleName('tourism/journey-calculation/aurelia-element'),
            PLATFORM.moduleName('tourism/manage-equipment/manage-equipment'),
            PLATFORM.moduleName('tourism/room-manager'),
            PLATFORM.moduleName('tourism/bus-routing/bus-routing-manager'),
            PLATFORM.moduleName('tourism/bus-routing/bus-routing-map'),
            PLATFORM.moduleName('tourism/bus-routing/bus-routing-participant-view'),
            PLATFORM.moduleName('tourism/bus-routing/participant-assignment/participant-assignment'),
            PLATFORM.moduleName('tourism/seat-map/seat-map-assignment'),
            PLATFORM.moduleName('tourism/contingent-pool-display'),
            PLATFORM.moduleName('tourism/tour-guide/guide-participant-assignment'),
            PLATFORM.moduleName('map/sio-map'),
            PLATFORM.moduleName('value-converter/access-value-value-converter'),
            PLATFORM.moduleName('value-converter/debug-value-converter'),
            PLATFORM.moduleName('value-converter/escape-html-value-converter'),
            PLATFORM.moduleName('value-converter/military-time-value-converter'),
            PLATFORM.moduleName('value-converter/nl2br-value-converter'),
            PLATFORM.moduleName('value-converter/number-input-value-converter'),
            PLATFORM.moduleName('value-converter/time-input-value-converter'),
            PLATFORM.moduleName('value-converter/physical-size-value-converter'),
            PLATFORM.moduleName('value-converter/shrink-value-converter'),
            PLATFORM.moduleName('value-converter/thumbnail-value-converter'),
            PLATFORM.moduleName('value-converter/upcharge-value-converter'),
            PLATFORM.moduleName('value-converter/sort-value-converter'),
            PLATFORM.moduleName('video-reference-input/url-to-video-reference-value-converter'),
            PLATFORM.moduleName('video-reference-input/video-reference-input'),
            PLATFORM.moduleName('video-reference-input/videoplayer/videoplayer'),
            PLATFORM.moduleName('view-block/fullsize-column/fullsize-column'),
            PLATFORM.moduleName('view-block/google-maps-link/google-maps'),
            PLATFORM.moduleName('view-block/header/header'),
            PLATFORM.moduleName('view-block/info-pane/info-pane'),
            PLATFORM.moduleName('view-block/info-table/info-table'),
            PLATFORM.moduleName('view-block/layout-column/layout-column'),
            PLATFORM.moduleName('view-block/pane/pane'),
            PLATFORM.moduleName('view-block/panel/panel'),
            PLATFORM.moduleName('view-block/tab/count'),
            PLATFORM.moduleName('view-block/tab/new-tabs'),
            PLATFORM.moduleName('view-block/tab/presets/log'),
            PLATFORM.moduleName('view-block/tab/presets/overview'),
            PLATFORM.moduleName('view-block/tab/presets/table'),
            PLATFORM.moduleName('view/inline-view'),
            PLATFORM.moduleName('view/view'),
            PLATFORM.moduleName('view/wiki'),
            PLATFORM.moduleName('fields/iframe/iframe'),
            PLATFORM.moduleName('widgets/bic-info'),
            PLATFORM.moduleName('widgets/form-elements/time/time-input'),
            PLATFORM.moduleName('widgets/form-elements/colorpicker/colorpicker'),
            PLATFORM.moduleName('widgets/form-elements/editable-table/editable-table'),
            PLATFORM.moduleName('tourism-contract/room-season-prices'),
            PLATFORM.moduleName('tourism-contract/discount-type'),

            // @deprecated old calculation
            PLATFORM.moduleName('tourism/old-calculation/calculation'),
            PLATFORM.moduleName('tourism/old-calculation/calculation-list'),
            PLATFORM.moduleName('tourism/old-calculation/number-display'),
            PLATFORM.moduleName('tourism/old-calculation/old-calculation'),
            PLATFORM.moduleName('tourism/old-calculation/old-calculation-list'),
            PLATFORM.moduleName('tourism/old-calculation/percentage-display')
        )
    ;

    // Uncomment the line below to enable animation.
    // aurelia.use.plugin(PLATFORM.moduleName('aurelia-animator-css'));
    // if the css animator is enabled, add swap-order="after" to all router-view elements

    // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
    // aurelia.use.plugin(PLATFORM.moduleName('aurelia-html-import-template-loader'))

    aurelia.start().then(() => aurelia.setRoot(
        (localStorage.getItem('access_token') || /\/login\/(activate|request|reset-password|redirect)/.test(location.href)) ?
            PLATFORM.moduleName('app') :
            PLATFORM.moduleName('login/login')
    ));

    // if you would like your website to work offline (Service Worker),
    // install and enable the @easy-webpack/config-offline package in webpack.config.babel.js and uncomment the following code:
    /*
     const offline = await System.import('offline-plugin/runtime');
     offline.install();
     */
}
